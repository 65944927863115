import { clamp, clone, cloneDeep, isNil, range } from "lodash";
import { getColorFromString } from "utils/common";

export const VIEW_W = 816;
const VIEW_H = 1000;

export const SF = 2;

export const FONT_SIZE = 16;
const LINE_HEIGHT = FONT_SIZE * 3.5;
const PADDING = 10;
const START_X = 95 * SF;
const START_Y = 120 * SF;
const END_X = (VIEW_W - 100) * SF;

export const T_START = "\u0010";
export const R_START = "\u0012";
export const C_START = "\u001c";
export const T_END = "\u0011";

export const TABLE_CHARS = [T_START, R_START, C_START, T_END];

const TWO_COLUMNS = `${T_START}${R_START}${C_START}Col 1 has\nmuch text has much text has much text has much text has much text${C_START}Col 2${R_START}${C_START}Col 3${C_START}Col 4${T_END}`;

const THREE_COLUMNS = `${T_START}${R_START}${C_START}Col 1 has much text${C_START}Col 2 ${C_START}Col 3${R_START}${C_START}Col 3${C_START}Col 4${C_START}Col 5${T_END}`;

export const TEXT_WITH_TABLE = `First \nThis is a whole line\nMore stuff line${TWO_COLUMNS}Another line${THREE_COLUMNS}`;

const STYLE_FIELD_TO_VALUE = {
  fontSize: 24,
  fontWeight: "bold",
};

const STYLE_FIELDS = Object.keys(STYLE_FIELD_TO_VALUE);

export const ARROW_KEYS = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

const isWordOverlappingEnd = ({ x, text, i, ctx, endX }) => {
  if (text?.[i] !== " ") {
    return false;
  }

  let word = "";
  let j = i + 1;
  while (text[j] !== " " && text[j] !== "\n" && j < text.length) {
    word += text[j];
    j++;
  }

  return x + ctx.measureText(word).width > endX;
};

const getTableCharPosition = ({ text, i, x, y, tableState }) => {
  const char = text[i];

  if (tableState?.maxRowY) {
    tableState.maxRowY = Math.max(tableState.maxRowY, y);
  }

  if (char === T_START) {
    const numColumns = getNumberOfColumns({ text, tStartI: i });
    tableState = {
      columnIndex: -1,
      columnWidth: (END_X - START_X) / numColumns,
      maxRowY: y,
      rowStartY: y,
    };
    x = START_X;
  }

  if (char === T_END) {
    y = tableState?.maxRowY + LINE_HEIGHT;
    x = START_X;
    tableState = null;
  }

  if (char === R_START) {
    tableState.columnIndex = -1;
    tableState.rowStartY = tableState.maxRowY + LINE_HEIGHT;

    x = START_X;
    y = tableState?.maxRowY + LINE_HEIGHT;
  }

  if (char === C_START) {
    tableState.columnIndex += 1;

    x = START_X + tableState.columnIndex * tableState.columnWidth + PAD;
    y = tableState.rowStartY;
  }

  return { x, y, tableState };
};

const getNextPosition = ({ x, y, text, i, ctx, tableState = null }) => {
  const char = text[i];
  const charWidth = ctx.measureText(char).width;

  // handle table
  if (TABLE_CHARS.includes(char)) {
    return getTableCharPosition({ x, y, i, text, tableState });
  }

  let endX = END_X;
  let startX = START_X;
  if (tableState) {
    endX =
      START_X + tableState.columnWidth * (tableState.columnIndex + 1) - PAD;
    startX = START_X + tableState.columnWidth * tableState.columnIndex + PAD;
  }

  let shouldStartNewLine =
    char === "\n" || isWordOverlappingEnd({ x, text, i, ctx, endX });
  if (shouldStartNewLine) {
    y += LINE_HEIGHT;
    x = startX;
    return { x, y, tableState };
  }

  x += charWidth;
  return { x, y, tableState };
};

const drawCaret = ({ x, y, ctx }) => {
  ctx.lineWidth = 4;
  ctx.beginPath();
  const bottomY = y - LINE_HEIGHT + 24;
  const topY = y + 8;
  ctx.moveTo(x, bottomY);
  ctx.lineTo(x, topY);
  ctx.stroke();
};

const drawSelectionBox = ({ x, y, ctx, char }) => {
  const prevFillStyle = ctx.fillStyle;
  ctx.fillStyle = "#0b57d033";
  ctx.fillRect(
    x,
    y - LINE_HEIGHT + 18,
    ctx.measureText(char).width,
    LINE_HEIGHT
  );
  ctx.fillStyle = prevFillStyle;
};

const CHAR_FILL = {
  [T_START]: "#ff000017",
  [R_START]: "#40ff001e",
  [C_START]: "#3300ff14",
  [T_END]: "#ffee0055",
  "\n": "#ffff0000",
  " ": "#2c2c2c00",
};
const DEBUG_CHARS = Object.keys(CHAR_FILL);

const drawCharBox = ({ x, y, ctx, char, fill = "salmon" }) => {
  const prevFillStyle = ctx.fillStyle;
  ctx.fillStyle = fill;
  ctx.fillRect(
    x,
    y - LINE_HEIGHT + 18,
    ctx.measureText(char).width,
    LINE_HEIGHT
  );
  ctx.fillStyle = prevFillStyle;
};

const drawQueryBox = ({ x, y, ctx, char, queryId }) => {
  const prevFillStyle = ctx.fillStyle;
  // ctx.fillStyle = `${getColorFromString(queryId)}22`;
  // ctx.fillRect(x, y - LINE_HEIGHT, ctx.measureText(char).width, LINE_HEIGHT);
  ctx.fillStyle = `${getColorFromString(queryId)}`;
  ctx.fillRect(0, y - LINE_HEIGHT + 18, 6, LINE_HEIGHT);
  ctx.fillStyle = prevFillStyle;
};

const getStartI = ({ scrollY, ys = [], text }) => {
  let i = 0;
  let tableStart = 0;
  let inTable = false;

  while (i < ys.length) {
    if (text?.[i] === T_START) {
      tableStart = i;
      inTable = true;
    }
    if (text?.[i] === T_END) {
      inTable = false;
    }

    if (ys[i] - scrollY > 0) {
      break;
    }

    i++;
  }

  // paint whole table, to avoid layout issues
  return inTable ? tableStart : i;
};

export const getNumberOfColumns = ({ text, tStartI }) => {
  let i = tStartI + 2;
  let numColumns = 0;

  while (text?.[i] !== R_START) {
    if (text?.[i] === C_START) {
      numColumns++;
    }

    if (text?.[i] === T_END || i >= text?.length) {
      break;
    }

    i++;
  }

  return numColumns;
};

export const getNumberOfRows = ({ text, tStartI }) => {
  let i = tStartI + 2;
  let numRows = 1;

  while (text?.[i] !== T_END && i < text?.length) {
    if (text?.[i] === R_START) {
      numRows++;
    }
    i++;
  }

  return numRows;
};

const PAD = 12;
const drawTableLines = ({ ctx, ys = [], text, startI, endI, scrollY }) => {
  let i = startI;
  ctx.lineWidth = 1;
  ctx.beginPath();

  let tStartY = 0;
  let tEndY = 0;
  let numColumns = 0;

  while (i < endI) {
    if (text?.[i] === T_START) {
      tStartY = ys[i] - scrollY;
      numColumns = getNumberOfColumns({ text, tStartI: i });
    }

    // horizontal lines
    if (text?.[i] === C_START && text?.[i - 1] === R_START) {
      ctx.moveTo(START_X, ys[i] - LINE_HEIGHT - scrollY + PAD);
      ctx.lineTo(END_X, ys[i] - LINE_HEIGHT - scrollY + PAD);
    }

    if (text?.[i] === C_START || text?.[i] === T_END) {
      tEndY = Math.max(tEndY, ys[i] - scrollY);
    }

    if (text?.[i] === T_END) {
      // last horizontal line
      ctx.moveTo(START_X, tEndY + PAD);
      ctx.lineTo(END_X, tEndY + PAD);

      // vertical lines
      ctx.moveTo(START_X, tStartY + PAD);
      ctx.lineTo(START_X, tEndY + PAD);

      let columnWidth = (END_X - START_X) / numColumns;
      range(numColumns).forEach(columnIndex => {
        ctx.moveTo(START_X + columnWidth * (columnIndex + 1), tStartY + PAD);
        ctx.lineTo(START_X + columnWidth * (columnIndex + 1), tEndY + PAD);
      });

      tStartY = 0;
      tEndY = 0;
      numColumns = 0;
    }

    i++;
  }

  ctx.stroke();
};

const drawPageSetup = ({ ctx, topMargin, scrollY }) => {
  const prevFillStyle = ctx.fillStyle;
  const prevStrokeStyle = ctx.strokeStyle;

  // reset
  ctx.lineWidth = 1;
  ctx.clearRect(0, 0, VIEW_W * SF, VIEW_H * SF);

  // white background
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, VIEW_W * SF, VIEW_H * SF);

  // page borders
  ctx.strokeStyle = "#868686";
  ctx.beginPath();

  // top
  ctx.moveTo(0, topMargin - scrollY);
  ctx.lineTo(VIEW_W * SF, topMargin - scrollY);

  // left
  ctx.moveTo(0, topMargin - scrollY);
  ctx.lineTo(0, VIEW_H * SF);

  // right
  ctx.moveTo(VIEW_W * SF, topMargin - scrollY);
  ctx.lineTo(VIEW_W * SF, VIEW_H * SF);

  ctx.stroke();

  // gap before first page
  ctx.clearRect(0, 0, VIEW_W * SF, topMargin - scrollY);

  ctx.fillStyle = prevFillStyle;
  ctx.strokeStyle = prevStrokeStyle;
};

export const drawDoc = ({
  doc,
  ctx,
  scrollY,
  xs = [],
  ys = [],
  topMargin = 0,
}) => {
  if (!ctx) {
    return [];
  }
  drawPageSetup({ ctx, topMargin, scrollY });

  const { text, selStart, selEnd, styles } = doc || EMPTY_DOC;
  let selSmaller = Math.min(selStart, selEnd);
  let selBigger = Math.max(selStart, selEnd);

  ctx.font = `${FONT_SIZE * SF}px Arial`;

  let newXs = [...xs];
  let newYs = [...ys];

  newXs[0] = START_X;
  newYs[0] = START_Y + topMargin;

  // let i = newYs.findIndex(y => y - scrollY > 0);
  let startI = getStartI({ scrollY, ys: newYs, text });

  let i = clamp(startI, 0, text.length);
  let x = newXs[i];
  let y = newYs[i];
  let tableState = null;

  while (i < text.length && i >= 0) {
    ctx.font = `${FONT_SIZE * SF}px Arial`;
    newXs[i] = x;
    newYs[i] = y;

    if (styles[i]?.fontSize) {
      ctx.font = `${styles[i]?.fontSize * SF}px Arial`;
    }
    if (styles[i]?.isBold) {
      ctx.font = `bold ${ctx?.font}`;
    }
    if (styles[i]?.isItalic) {
      ctx.font = `italic ${ctx?.font}`;
    }

    if (styles[i]?.queryId) {
      drawQueryBox({
        x,
        y: y - scrollY,
        ctx,
        char: text[i],
        queryId: styles[i].queryId,
      });
    }

    const factcheking = window?.location?.href?.includes("factchecking=true");
    if (styles?.[i]?.metas && factcheking) {
      drawCharBox({
        x,
        y: y - scrollY,
        ctx,
        char: text[i],
        fill: "#00ff0022",
      });
    }

    if (i === selStart && i === selEnd) {
      drawCaret({ x, y: y - scrollY, ctx });
    }

    // debug
    // if (DEBUG_CHARS.includes(text[i])) {
    //   drawCharBox({
    //     x,
    //     y: y - scrollY,
    //     ctx,
    //     char: text[i],
    //     fill: CHAR_FILL[text[i]],
    //   });
    // }

    if (i >= selSmaller && i < selBigger) {
      drawSelectionBox({ x, y: y - scrollY, ctx, char: text[i] });
    }

    ctx.fillText(text[i], x, y - scrollY);
    ({ x, y, tableState } = getNextPosition({
      x,
      y,
      tableState,
      text,
      i,
      ctx,
    }));

    if (y - scrollY > VIEW_H * SF && !tableState && text?.[i] !== T_END) {
      // SOLVES: coordinates beyond the page could become out of date.
      // caused issues when finding nearest index from mouse event
      newXs = newXs.slice(0, i + 1);
      newYs = newYs.slice(0, i + 1);
      break;
    }

    i++;
  }

  drawTableLines({ ctx, ys: newYs, text, startI, endI: i, scrollY });

  if (selStart === selEnd && selStart === text.length) {
    drawCaret({ x, y: y - scrollY, ctx });
  }

  return [newXs, newYs];
};

const getNearestCharIndex = ({ x, y, xs = [], ys = [] }) => {
  let i = ys.findIndex(yCoord => yCoord > y);
  const initialY = ys[i];

  let xDistance = Infinity;
  let minI = i;

  while (i < xs?.length) {
    const d = Math.abs(xs[i] - x);
    if (d < xDistance && ys[i] === initialY) {
      xDistance = d;
      minI = i;
    }
    i++;
  }

  if (minI === xs.length - 1 && x > xs?.[minI] + 10) {
    minI++;
  }

  if (minI === -1) {
    minI = xs.length;
  }

  return minI;
};

export const getNearestCharIndexFromEvent = (
  e,
  scrollY = 0,
  xs = [],
  ys = [],
  text = ""
) => {
  const { offsetX, offsetY } = e?.nativeEvent;
  const x = offsetX * SF;
  const y = offsetY * SF;
  let nearestIndex = getNearestCharIndex({
    x,
    y: y + scrollY,
    xs,
    ys,
  });

  return safeCaret(nearestIndex, text);
};

export const insertText = ({ doc = {}, textToInsert = "" }) => {
  let newDoc = cloneDeep(doc);
  const selSmaller = Math.min(newDoc.selStart, newDoc.selEnd);
  const selBigger = Math.max(newDoc.selStart, newDoc.selEnd);

  newDoc.text =
    newDoc.text.slice(0, selSmaller) +
    textToInsert +
    newDoc.text.slice(selBigger);

  const styleAtStart = newDoc.styles[selSmaller];
  newDoc.styles = [
    ...newDoc.styles.slice(0, selSmaller),
    ...Array(textToInsert.length).fill(styleAtStart),
    ...newDoc.styles.slice(selBigger),
  ];

  // if press Enter in AI style
  if (
    textToInsert === "\n" &&
    styleAtStart?.queryId &&
    selSmaller === selBigger
  ) {
    const nextChar = doc?.text?.[selBigger + 1];
    let nextCharStyle = { ...doc?.styles?.[selBigger] };
    if (nextChar === "\n" || TABLE_CHARS?.includes(nextChar)) {
      nextCharStyle = null;
    }

    newDoc.styles = [
      ...doc?.styles?.slice(0, selSmaller),
      null,
      nextCharStyle,
      ...doc?.styles?.slice(selBigger + 1),
    ];
  }

  newDoc.selStart += textToInsert.length;
  newDoc.selEnd = newDoc.selStart;

  return newDoc;
};

const shouldSkipChar = (i, text) => {
  const prevChar = text?.[i - 1];
  const char = text?.[i];

  if (char === R_START && prevChar === C_START) {
    return false;
  }

  if (char === C_START && prevChar === C_START) {
    return false;
  }

  if (char === T_END) {
    return false;
  }

  return TABLE_CHARS?.includes(prevChar) && TABLE_CHARS?.includes(char);
};

const safeCaret = (i, text = "", direction = "forward") => {
  if (!shouldSkipChar(i, text)) {
    return i;
  }

  while (shouldSkipChar(i, text) && i < text.length - 1 && i >= 0) {
    i += direction === "forward" ? 1 : -1;
  }

  return i;
};

const moveCaretToLine = ({ i, xs = [], ys = [], direction = "forward" }) => {
  let increment = direction === "forward" ? 1 : -1;

  let initialY = ys[i];
  let targetY = null;
  let j = i;

  let xDistance = Infinity;
  let closestIndex = i;

  while (j >= 0 && j < ys?.length) {
    let y = ys[j];

    if (isNil(targetY) && y !== initialY) {
      targetY = y;
    }

    let newXDistance = Math.abs(xs[j] - xs[i]);
    if (y === targetY && newXDistance < xDistance) {
      xDistance = newXDistance;
      closestIndex = j;
    }

    if (!isNil(targetY) && y !== targetY) {
      break;
    }

    j += increment;
  }

  return closestIndex;
};

export const moveCaret = ({ doc = {}, key = "", xs = [], ys = [] }) => {
  let newDoc = cloneDeep(doc);
  let caretIndex = newDoc.selStart;
  let direction = "forward";

  if (key === "ArrowDown") {
    caretIndex = moveCaretToLine({ i: caretIndex, xs, ys, direction });
  }
  if (key === "ArrowUp") {
    direction = "back";
    caretIndex = moveCaretToLine({ i: caretIndex, xs, ys, direction });
  }
  if (key === "ArrowRight") {
    caretIndex++;
  }
  if (key === "ArrowLeft") {
    direction = "back";
    caretIndex--;
  }
  caretIndex = Math.max(0, Math.min(newDoc.text.length, caretIndex));

  newDoc.selStart = safeCaret(caretIndex, newDoc.text, direction);
  newDoc.selEnd = newDoc.selStart;

  return newDoc;
};

export const deleteText = ({ doc = {} }) => {
  let newDoc = cloneDeep(doc);

  if (newDoc.selStart === newDoc.selEnd) {
    newDoc.selStart--;
  }

  const selSmaller = Math.max(0, Math.min(newDoc.selStart, newDoc.selEnd));
  const selBigger = Math.max(newDoc.selStart, newDoc.selEnd);

  newDoc.text = newDoc.text.slice(0, selSmaller) + newDoc.text.slice(selBigger);
  newDoc.styles = [
    ...newDoc.styles.slice(0, selSmaller),
    ...newDoc.styles.slice(selBigger),
  ];
  newDoc.xs = [];
  newDoc.ys = [];

  newDoc.selStart = selSmaller;
  newDoc.selEnd = selSmaller;

  return newDoc;
};

export const EMPTY_DOC = {
  text: "",
  styles: [],
  selStart: 0,
  selEnd: 0,
};
