import ColumnFieldInput from "components/ColumnFieldInput";
import { Gap } from "components/Layout";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const Container = styled.div`
  padding: 10px;
  background-color: #eaeaea;
  padding-left: 50px;
  padding-top: 20px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Td = styled.td`
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px 20px;

  font-weight: 600;
  z-index: 1;
`;

const Table = styled.table`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 12px;
  border-collapse: separate;
  background-color: white;
  width: 100%;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props?.disabled && "pointer-events: none; opacity: 0.5;"}
`;

const INNER_COLUMN_FIELDS = [
  { label: "Name", name: "displayName" },
  {
    label: "Action type",
    name: "actionType",
    type: "select",
    options: [
      { label: "Extraction", value: "extraction" },
      { label: "Manual", value: "manual" },
      { label: "Reasoning", value: "reasoning" },
    ],
  },
  { label: "Description", name: "description" },
  {
    label: "Data Type",
    name: "type",
    type: "select",
    options: [
      {
        label: "TEXT",
        value: "TEXT",
      },
      {
        label: "NUMBER",
        value: "NUMBER",
      },
      {
        label: "DATETIME",
        value: "DATETIME",
      },
    ],
  },
];

const InnerColumnsEditor = ({
  columns = [],
  onChangeColumns = newColumns => {},
}) => {
  const addColumn = () => {
    const newColumns = [
      ...(columns || []),
      {
        id: uuidv4(),
        actionType: "extraction",
        description: "",
        displayName: "Name",
        type: "TEXT",
      },
    ];
    onChangeColumns(newColumns);
  };

  const removeColumn = columnId => {
    const newColumns = columns.filter(column => column.id !== columnId);
    onChangeColumns(newColumns);
  };

  const onChangeColumn = (columnId, newValue, key) => {
    const newColumns = columns.map(column =>
      column.id === columnId ? { ...column, [key]: newValue } : column
    );
    onChangeColumns(newColumns);
  };

  return (
    <Container>
      <Table>
        <thead>
          {INNER_COLUMN_FIELDS.map(field => (
            <Th key={field?.name}>{field?.label}</Th>
          ))}
          <Th />
        </thead>
        <tbody>
          {columns.map((column, index) => (
            <tr>
              {INNER_COLUMN_FIELDS.map(field => (
                <Td>
                  <ColumnFieldInput
                    key={field?.name}
                    value={column[field?.name]}
                    onChange={e =>
                      onChangeColumn(column?.id, e.target.value, field?.name)
                    }
                    columnType={field?.type}
                    options={field?.options}
                  />
                </Td>
              ))}
              <Td>
                <StyledTrashIcon onClick={() => removeColumn(column?.id)} />
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Gap height="10px" />
      <StyledPlusIcon onClick={() => addColumn()} />
    </Container>
  );
};

export default InnerColumnsEditor;
