import {
  postDirectories,
  postMoveItem,
  postSigmasV2,
  putPipelineConfig,
} from "api/backend/fileSystemEndpoints";
import { postSequencesCreate } from "api/backend/projectServiceEndpoints";
import { getWordDoc, postWordDoc } from "api/backend/wordDocsEndpoints";
import {
  getAppBasePathSource,
  getAppBasePathWorking,
} from "components/LayoutApp";
import { cloneDeep, set } from "lodash";
import { uuidv4 } from "utils/common";

const FIRST_STEP_ID = uuidv4();
const INITIAL_SEQUENCE_BODY = {
  meta: {
    layout: {
      [FIRST_STEP_ID]: {
        h: 4,
        w: 10,
        x: 8,
        y: 7,
      },
      step0: {
        h: 4,
        w: 10,
        x: 8,
        y: 1,
      },
    },
  },
  name: "Auto email example",
  sharedWith: [
    {
      accessLevel: "Owner",
      userGroupId: "test-user",
    },
  ],
  steps: [
    {
      action: {
        condition: "",
        offsetMS: 0,
        type: "",
      },
      function: {
        prompt: "",
        recipients: null,
        savePath: "",
        subject: "",
        template: "",
        type: "",
      },
      id: "step0",
      name: "Sequence trigger",
      next: [FIRST_STEP_ID],
      output: null,
      type: "manually-triggered",
    },
    {
      action: {
        condition: "",
        offsetMS: 0,
        type: "",
      },
      function: {
        prompt: "",
        recipients: ["Dets_Email"],
        savePath: "",
        subject: "Greetings",
        template: "Welcome to the app",
        type: "",
      },
      id: FIRST_STEP_ID,
      name: "Send email",
      next: null,
      output: null,
      type: "email",
    },
  ],
};

export const doCreateInitialPipelineResources = async pipelineConfig => {
  // initialise app folders
  await postDirectories({
    path: getAppBasePathSource(pipelineConfig?.id),
  });
  await postDirectories({
    path: getAppBasePathWorking(pipelineConfig?.id),
  });

  // initialise with blank record
  const recordNameColumn = pipelineConfig?.sourceTables?.[0]?.columns?.find(
    column => !column?.displayGroup
  );

  // initalise word docs
  const wordDocColumns = pipelineConfig?.sourceTables?.[0]?.columns?.filter(
    column => column?.displayGroup === "File Templates"
  );
  let wordDocFields = {};
  const wordDocPromises = wordDocColumns?.map(async column => {
    const { data: templateWordDoc } = await getWordDoc(column?.description);

    // move template word doc
    await postMoveItem({
      id: templateWordDoc?.id,
      newDirectoryPath: getAppBasePathWorking(pipelineConfig?.id),
      itemType: "WORD_DOC",
    });

    const { data: wordDoc } = await postWordDoc(
      {},
      {
        fileName: column?.name,
        content: templateWordDoc?.content,
      }
    );
    wordDocFields[column.name] = {
      value: wordDoc.id,
    };
  });
  await Promise.all(wordDocPromises);

  await postSigmasV2(
    {},
    {
      pipelineId: pipelineConfig?.id,
      tableName: pipelineConfig?.sourceTables?.[0]?.id,
      fields: {
        [recordNameColumn?.displayName]: {
          value: "New Record",
        },
        ...wordDocFields,
      },
    }
  );

  // initialise plot
  const componentId = uuidv4();
  const aggregationComponents = [
    {
      id: componentId,
      label: "Record Count",
      nlc: "count records",
      type: "Table",
      data: {
        execResult: {
          columnTypes: {
            count: "UNKNOWN",
          },
          message: "",
          records: [
            {
              createdAt: "0001-01-01T00:00:00Z",
              createdBy: "",
              fields: {
                count: {
                  reference: null,
                  value: 1,
                },
              },
              id: 0,
              pipelineId: "",
              tableName: "",
              updatedAt: "0001-01-01T00:00:00Z",
            },
          ],
          sql: "SELECT COUNT(*) FROM source0;",
        },
        plots: [
          {
            type: "Table",
            xColumnName: "",
            yColumnNames: [],
          },
        ],
      },
    },
  ];
  const dashboardLayout = {
    [componentId]: {
      h: 14,
      w: 24,
      x: 2,
      y: 2,
    },
  };
  const newPipelineConfig = cloneDeep(pipelineConfig);
  newPipelineConfig.aggregationComponents = aggregationComponents;
  set(newPipelineConfig, "meta.dashboardLayout", dashboardLayout);
  await putPipelineConfig(pipelineConfig?.id, {}, newPipelineConfig);

  // initial sequence
  const sequencePayload = {
    pipelineId: pipelineConfig?.id,
    ...INITIAL_SEQUENCE_BODY,
  };

  const emailColumnName = pipelineConfig?.sourceTables?.[0]?.columns?.find(
    column => column?.type === "EMAIL"
  )?.name;
  sequencePayload.steps[1].function.recipients = [emailColumnName];

  await postSequencesCreate({}, sequencePayload);
};
