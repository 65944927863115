import { PencilIconDetailed } from "components/IconsNew";
import InpsectModal from "components/InspectModal";
import { Gap } from "components/Layout";
import { PLOT_TYPES, getPlotComponent } from "components/plots";
import ButtonWord from "components/ui/ButtonWord";
import { DownloadIcon, FilesIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { range, uniq } from "lodash";
import {
  getBlockTextSegments,
  renderSegments,
} from "pages/apps/:pipelineConfigId/chat";
import { useState } from "react";
import styled from "styled-components";

import { Editor } from "@monaco-editor/react";
import { useEffect } from "react";

const StyledEditor = styled(Editor)`
  height: 150px;
`;

const Step = styled.div`
  padding: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
`;

const StepTitle = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
`;

const getTable = (blocks, tableId, numRows, numCols) => {
  const tableColumns = blocks
    ?.filter(b => b?.tableId === tableId && b?.rowIndex === 0)
    ?.map(b => ({ name: b?.text }));
  let records = [];

  range(1, numRows)?.forEach(rowIndex => {
    const record = {};
    range(numCols)?.forEach(colIndex => {
      const columnName = tableColumns?.[colIndex]?.name;
      const cellBlock = blocks?.find(
        b =>
          b?.tableId === tableId &&
          b?.rowIndex === rowIndex &&
          b?.columnIndex === colIndex
      );
      record[columnName] = {
        value: parseFloat(cellBlock?.text) || cellBlock?.text,
      };
    });
    records.push(record);
  });

  return { tableColumns, records };
};

const DownloadIconContainer = styled.div`
  height: max-content;
  width: max-content;
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
  svg {
    fill: black;
  }
`;

const Td = styled.td`
  padding: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
`;

const TableConainer = styled.div`
  position: relative;
  padding-left: 0px;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 0px;
  max-height: 200px;
`;

const IconsRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    height: 16px;
    fill: black;
    opacity: 0.6;
    cursor: pointer;
    :hover {
      opacity: 1;
    }
  }
`;

const ModalContent = styled.div`
  width: 800px;
  height: 600px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 10px;
  align-content: start;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const StyledSelect = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
`;

const SAMPLE_CODE = `SELECT * FROM table_name
WHERE column_name = value`;

const Code = styled.div`
  background-color: #f3f5f7;
  padding: 10px;
  border-radius: 10px;
  white-space: pre-wrap;
  font-weight: 500;
  line-height: 1.2;
  height: 150px;
  overflow: auto;
`;

const TwoColumns = styled.div`
  display: grid;
  /* grid-template-columns: 350px 350px; */
  gap: 20px;
  margin-top: 20px;
`;

const MONACO_OPTIONS = {
  lineNumbers: "off",
  minimap: { enabled: false },
  scrollbar: { vertical: "hidden", horizontal: "hidden" },
  readOnly: true,
  lineDecorationsWidth: 0,
};

const ChatTablePlotModal = ({
  block = {},
  blocks = [],
  isGenerating = false,
  doDownloadTable = () => {},
  copyTableToClipboard = () => {},
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plotType, setPlotType] = useState("Table");
  const [plotProps, setPlotProps] = useState({
    params: {},
    marginBottom: 0,
    isEditing: false,
    onChangeParams: newParams => {
      setPlotProps({ ...plotProps, params: newParams });
    },
    tableColumns: [],
    records: [],
    height: 400,
  });

  const numRows = block?.numberOfRows;
  const numCols = block?.numberOfColumns;
  const tableId = block?.tableId;

  useEffect(() => {
    if (!block?.displayConfig) {
      return;
    }

    let plotTypeToSet = block?.displayConfig?.type || "Table";
    // disable key figures in chat
    if (plotTypeToSet === "Key Figures") {
      plotTypeToSet = "Table";
    }

    setPlotType(plotTypeToSet);
    setPlotProps(prev => ({
      ...prev,
      params: { ...(prev?.params || {}), ...(block?.displayConfig || {}) },
    }));
  }, [JSON.stringify(block?.displayConfig)]);

  let tableContent = (
    <TableConainer>
      <table>
        <tbody>
          {range(numRows).map(rowIndex => (
            <tr key={`${tableId}-row-${rowIndex}`}>
              {range(numCols).map(colIndex => {
                const cellBlock = blocks?.find(
                  b =>
                    b?.tableId === tableId &&
                    b?.rowIndex === rowIndex &&
                    b?.columnIndex === colIndex
                );

                const segments = getBlockTextSegments(cellBlock);

                let cellJson = null;
                try {
                  cellJson = JSON.parse(cellBlock?.text);
                } catch {
                  cellJson = null;
                }

                const shouldRenderInspectModal =
                  (cellJson && typeof cellJson === "object") ||
                  cellBlock?.text?.includes('"data":');

                if (shouldRenderInspectModal) {
                  const columnNameBlocks = blocks?.filter(
                    b => b?.tableId === tableId && b?.rowIndex === 0
                  );
                  const rowBlocks = blocks?.filter(
                    b => b?.tableId === tableId && b?.rowIndex === rowIndex
                  );
                  const columnNames = columnNameBlocks?.map(b =>
                    b?.text?.trim()
                  );
                  const rowValues = rowBlocks?.map(b => b?.text?.trim());

                  return (
                    <Td key={`${tableId}-row-${rowIndex}-col-${colIndex}`}>
                      <InpsectModal
                        data={cellJson?.data || []}
                        title={cellJson?.title || ""}
                        columnNames={columnNames}
                        rowValues={rowValues}
                      />
                    </Td>
                  );
                }

                // if (isModalOpen) {
                //   console.log(rowIndex, colIndex, { cellBlock });
                // }

                return (
                  <Td key={`${tableId}-row-${rowIndex}-col-${colIndex}`}>
                    {renderSegments(segments, cellBlock, isGenerating)}
                  </Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </TableConainer>
  );
  let { tableColumns, records } = getTable(blocks, tableId, numRows, numCols);

  if (block?.records && block?.columns) {
    let colunmNames = Object?.keys(block?.columns || {});
    tableColumns = colunmNames?.map(c => ({ name: c }));
    records = block?.records?.map(r => {
      let newRecord = {};
      colunmNames?.forEach(colName => {
        newRecord[colName] = { value: r?.[colName] };
      });

      return newRecord;
    });
  }

  if (block?.records) {
    tableContent = (
      <TableConainer>
        <table>
          <tbody>
            <tr>
              {tableColumns.map(col => (
                <Td key={col.name}>{col.name}</Td>
              ))}
            </tr>
            {records.map((record, i) => (
              <tr key={i}>
                {tableColumns.map(col => (
                  <Td key={col.name}>{record[col.name]?.value || "N/A"}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </TableConainer>
    );
  }

  let plotContent = tableContent;
  if (plotType !== "Table") {
    plotContent = getPlotComponent(plotType, {
      ...plotProps,
      isEditing: isModalOpen,
      tableColumns,
      records,
    });
  }

  const sourceCodes = blocks?.find(
    b => b?.queryId === block?.queryId && b?.sourceCodes?.length
  )?.sourceCodes;

  const tableIds = uniq(
    blocks
      ?.filter(b => b?.queryId === block?.queryId && b?.tableId)
      ?.map(b => b?.tableId)
  );

  const tableIndex = tableIds?.indexOf(tableId);

  const sourceCode = sourceCodes?.[tableIndex];

  let codeContent = (
    <TwoColumns>
      {sourceCode?.type === "sql" && (
        <Step>
          <StepTitle>SQL</StepTitle>
          <StyledEditor
            value={sourceCode?.code}
            language="sql"
            options={MONACO_OPTIONS}
          />
        </Step>
      )}
      {sourceCode?.type === "python" && (
        <Step>
          <StepTitle>Python</StepTitle>
          <StyledEditor
            value={sourceCode?.code}
            language="python"
            options={MONACO_OPTIONS}
          />
        </Step>
      )}
    </TwoColumns>
  );

  if (isModalOpen) {
    return (
      <Modal open handleClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <div
            style={{ display: "grid", overflow: "auto", alignContent: "start" }}
          >
            <ModalTitle>Configure plot</ModalTitle>
            <Gap />
            <StyledSelect
              value={plotType}
              onChange={e => setPlotType(e.target.value)}
              style={{ marginBottom: 20 }}
            >
              {PLOT_TYPES?.filter(type => type !== "Key Figures")?.map(
                plotType => (
                  <option key={plotType} value={plotType}>
                    {plotType}
                  </option>
                )
              )}
            </StyledSelect>
            {plotContent}
            {codeContent}
          </div>
          <ButtonWord
            style={{ justifySelf: "end" }}
            onClick={() => setIsModalOpen(false)}
          >
            OK
          </ButtonWord>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <div
      style={{
        paddingLeft: 48,
        gap: 10,
        position: "relative",
        display: "grid",
      }}
    >
      <IconsRow>
        <DownloadIconContainer
          style={{ paddingTop: 4 }}
          onClick={() => doDownloadTable(blocks, tableId)}
        >
          <DownloadIcon />
        </DownloadIconContainer>
        <DownloadIconContainer
          style={{ paddingTop: 4 }}
          onClick={() => copyTableToClipboard(blocks, tableId)}
        >
          <FilesIcon />
        </DownloadIconContainer>
        <PencilIconDetailed onClick={() => setIsModalOpen(true)} />
      </IconsRow>
      {plotContent}
      <Gap />
    </div>
  );
};

export default ChatTablePlotModal;
