import RecordsSummaryBarChart from "components/chat-plots/RecordsSummaryBarChart";
import RecordsSummaryLineChart from "components/chat-plots/RecordsSummaryLineChart";
import RecordsSummaryPieChart from "components/chat-plots/RecordsSummaryPieChart";
import { isEmpty } from "lodash";
import styled from "styled-components";

const Container = styled.div`
  /* display: grid;
  align-content: start; */
  overflow: auto;
  width: 100%;
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Tr = styled.tr`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const GreyRect = styled.div`
  height: 20px;
  align-self: center;
  border-radius: 4px;

  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 30%,
      rgba(255, 255, 255, 0) 80%
    ),
    #e0e0e0;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: -20 0;
  animation: shine 1s infinite;

  @keyframes shine {
    to {
      background-position: 100% 0, /* move highlight to right */ 0 0;
    }
  }
`;

const LoadingTh = () => (
  <Th>
    <GreyRect />
  </Th>
);
const LoadingTd = () => (
  <Td>
    <GreyRect />
  </Td>
);

const TableLoadingState = () => (
  <Container>
    <table>
      <thead>
        <tr>
          <LoadingTh />
          <LoadingTh />
          <LoadingTh />
        </tr>
      </thead>
      <tbody>
        <tr>
          <LoadingTd />
          <LoadingTd />
          <LoadingTd />
        </tr>
        <tr>
          <LoadingTd />
          <LoadingTd />
          <LoadingTd />
        </tr>
      </tbody>
    </table>
  </Container>
);

const RecordsPlotSql = ({
  columnTypes = {},
  records = [],
  isLoading = false,
}) => {
  const columns = Object.entries(columnTypes || {})
    .map(([key, value]) => ({
      name: key,
    }))
    ?.filter(
      column =>
        !column?.name?.includes("__raw") && !column?.name?.includes("__ref")
    );

  if (isLoading) {
    return <TableLoadingState />;
  }

  if (isEmpty(columnTypes)) {
    return null;
  }

  return (
    <Container>
      <table>
        <thead>
          <Tr>
            {columns?.map(column => (
              <Th key={column?.name}>{column?.name}</Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {records?.map(record => (
            <Tr key={record.id}>
              {columns?.map(column => (
                <Td key={column?.name}>
                  {record?.fields?.[column?.name]?.value}
                </Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default RecordsPlotSql;
