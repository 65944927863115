import { Save } from "@material-ui/icons";
import { BoldIcon, ItalicIcon, TableIcon } from "components/IconsNew";
import { cloneDeep, isEmpty } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { FONT_SIZE } from "utils/word-utils-refactor";

const getSelectionStyle = doc => {
  const selectedStyles = doc?.styles.slice(doc.selStart, doc.selEnd);

  if (!selectedStyles?.length) {
    return { isBold: false, isItalic: false, fontSize: 16 };
  }

  return {
    isBold: selectedStyles?.every(style => style?.isBold),
    isItalic: selectedStyles?.every(style => style?.isItalic),
    fontSize: selectedStyles?.find(style => style?.fontSize)?.fontSize || 16,
  };
};

const toggleStyle = (doc, styleField) => {
  const selectedStyles = doc?.styles.slice(doc.selStart, doc.selEnd);
  const isActive = selectedStyles?.every(style => style?.[styleField]);

  const newDoc = cloneDeep(doc);
  let i = doc.selStart;
  while (i < doc.selEnd) {
    if (isActive) {
      delete newDoc.styles[i][styleField];
      if (isEmpty(newDoc.styles[i])) {
        newDoc.styles[i] = null;
      }
    } else {
      newDoc.styles[i] = {
        ...newDoc.styles[i],
        [styleField]: true,
      };
    }
    i++;
  }

  return newDoc;
};

const addStyleField = (doc, fieldName, fieldValue) => {
  const newDoc = cloneDeep(doc);

  if (fieldName === "fontSize") {
    const fontSize = parseInt(fieldValue);

    let i = doc.selStart;
    while (i < doc.selEnd) {
      if (fontSize === FONT_SIZE) {
        delete newDoc.styles[i]?.fontSize;
        if (isEmpty(newDoc.styles[i])) {
          newDoc.styles[i] = null;
        }
      } else {
        newDoc.styles[i] = {
          ...newDoc.styles[i],
          fontSize,
        };
      }
      i++;
    }
  }

  return newDoc;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    fill: black;
    height: 28px;
    width: 28px;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    :hover {
      background-color: #eaeaea;
    }

    ${props => props.isActive && `background-color: #0191ff;`}
  }
`;

let ACTIVE_STYLE = {
  backgroundColor: "#0191ff22",
};

const Label = styled.label`
  border: 1px solid #ccc;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  gap: 4px;
  cursor: pointer;
`;

const Select = styled.select`
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

const DocToolbar = ({
  doc = {},
  onDocChange = newDoc => {},
  onClickSave = () => {},
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const selectionStyle = getSelectionStyle(doc);

  const { isBold, isItalic, fontSize } = selectionStyle;

  const doToggleStyle = styleField => {
    const newDoc = toggleStyle(doc, styleField);
    onDocChange(newDoc);
  };

  const doAddStyleField = (doc, fieldName, fieldValue) => {
    const newDoc = addStyleField(doc, fieldName, fieldValue);
    onDocChange(newDoc);
  };

  const factchecking = searchParams.get("factchecking") === "true";

  const onCheckChange = e => {
    if (factchecking) {
      searchParams.delete("factchecking");
    } else {
      searchParams.set("factchecking", "true");
    }

    navigate({ search: searchParams.toString() });
  };

  return (
    <Container>
      <BoldIcon
        style={isBold ? ACTIVE_STYLE : {}}
        onClick={() => doToggleStyle("isBold")}
      />
      <ItalicIcon
        style={isItalic ? ACTIVE_STYLE : {}}
        onClick={() => doToggleStyle("isItalic")}
      />
      <TableIcon />
      <Select
        value={fontSize}
        onChange={e => doAddStyleField(doc, "fontSize", e.target.value)}
      >
        <option value={16}>Normal text</option>
        <option value={20}>Sub title</option>
        <option value={28}>Title</option>
      </Select>
      <Label>
        <input
          style={{ margin: 0 }}
          onChange={onCheckChange}
          type="checkbox"
          checked={factchecking}
        />
        Check
      </Label>
      <Save onClick={onClickSave} style={{ padding: 4 }} />
    </Container>
  );
};

export default DocToolbar;
